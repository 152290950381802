import React from 'react';


const Contact:React.FC = () => {

  return (
    <div className="Contact flex-l w-30 center mv5 white fw2">
      <div className="flex-l w-50-l">
        <h2>Please reach out for any inquiries!</h2>
      </div>
      <div className="w-50-l">
        <div className="mv2"><b><u>Artist</u></b></div>
        <div>Shay Page</div>
        <div className="mt4"><b><u>Email</u></b></div>
        <div className="mv2">
          <div>info@theuntitledarts.com</div>
        </div>
      </div>

    </div>
  );
};

export default Contact;
